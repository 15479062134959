const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = globalThis.Convermax.config?.wheelsFields || [
  'wheel_diameter',
  'wheel_width',
  'wheel_bolt_pattern',
];
const tiresFields = globalThis.Convermax.config?.tiresFields || ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const isVehicleLanding = !!globalThis.Convermax.config?.isVehicleLanding;
const vehicleLandingPageView = globalThis.Convermax.config?.vehicleLandingPageView ?? 'products';

// do not show the Vehicle facet in the current search
if (vehicleLandingPageView === 'products') {
  ignoreFields.push('Vehicle');
}

const { customerCompanyId } = globalThis.Convermax;

// if the theme has a currency selector for markets, the URL should contain a culture code based on the selected currency
const cultureCode = globalThis.Shopify?.routes?.root.slice(0, -1) ?? '';
const searchPageUrl = `${cultureCode}/pages/search`;

const getStoreCurrency = () => globalThis.Shopify.currency?.active;

const handleCurrencies = (selector) => {
  if (window.Currency) {
    // window.Currency.cookie.read() can cause exception "Uncaught TypeError: jQuery.cookie is not a function"
    try {
      const currentCurrency = window.Currency?.cookie.read();
      window.Currency.convertAll(getStoreCurrency(), currentCurrency, selector);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug(e.message);
    }
  }
};

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: getStoreCurrency(),
  }).format(price);
}

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

function InitFunc() {
  if (window.document.querySelector('#cm_results, #cm-brand-list')) {
    const unwantedPageWrappers = [['.page-content.rte', 'page-content', 'rte']];

    unwantedPageWrappers.forEach(([elemSelector, ...classNames]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(...classNames);
    });
  }
}
function updateCallback() {
  handleCurrencies('.cm_SearchResult .price__current .money');

  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }

  handleHoverOnItem();
}

export default {
  platform: 'shopify',
  InitFunc,
  searchPageUrl,
  SearchRequestDefaults: {
    pageSize: globalThis.Convermax.config?.pageSize || 36,
    extra: customerCompanyId ? { customerCompanyId } : {},
    sort: window.Convermax.config?.defaultSort ?? 'relevance',
  },
  autocomplete: { requestDefaults: { extra: customerCompanyId ? { customerCompanyId } : {} } },
  product: {
    searchResultsImageWidth: 380,
    searchResultsImageHeight: 380,
    formatPrice,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      {
        pathname: `${cultureCode}/collections`,
        field: window.Convermax.config?.categorySelectionPageField ?? 'category',
      },
      {
        pathname: window.document.querySelector('#cm-brand-list') ? window.location.pathname : null,
        field: 'vendor',
      },
      {
        pathname:
          isVehicleLanding && vehicleLandingPageView === 'categories' ? window.location.pathname : null,
        field: 'category',
      },
    ],
    categorySelectionPageRedirect: !!window.Convermax.config?.categorySelectionPageRedirect,
    doNotSaveSelectedVehicle: !!window.Convermax.config?.doNotSaveSelectedVehicle,
    expectResponseRedirect: true,
    forceVehicleSelection: !!window.Convermax.config?.forceVehicleSelection,
    isAutoRedirectDisabled: !!window.Convermax.config?.isAutoRedirectDisabled,
    isVehicleSelectionIsolated: !!window.Convermax.config?.isVehicleSelectionIsolated,
    hideVehicleFromUrl: !!window.Convermax.config?.hideVehicleFromUrl,
    vehicleDataExpires: window.Convermax.config?.vehicleDataExpires,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { replace: '#cm_results', class: 'productgrid--outer' },
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
      visibleIf: () => !isVehicleLanding || vehicleLandingPageView === 'products',
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: '#cm-subcategory-list',
      template: 'facetTiles/subtitles',
      visibleIf: () =>
        (!isVehicleLanding || vehicleLandingPageView === 'products') &&
        window.location.pathname !== '/collections/vendors',
      facetField: 'category',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, low to high',
        'price:desc': 'Price, high to low',
        'published_at': 'Date, old to new',
        'published_at:desc': 'Date, new to old',
      },
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: '.live-search[data-live-search]',
      template: 'searchBox/dialogButtonAdaptable',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root div.price'),
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'MobileSearchBox',
      type: 'SearchBoxDialogButton',
      location: {
        replace: '.site-header-mobile-search-button',
        class: 'cm_search-box-root__dialog_open-button__mobile site-header-mobile-search-button--button',
      },
      template: 'searchBox/dialogButtonMobile',
      visibleIf: () => window.Convermax?.config?.isSearchBoxEnabled,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 770,
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
    },
    {
      name: 'HeaderVehicleWidgetStub',
      type: 'VehicleWidget',
      location: '#cm-ymm-stub',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 770,
      initCollapsed: !!window.Convermax.config?.ymmInitCollapsed,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && extraFitmentFields,
      visibleIf: () => !!window.Convermax.config?.extraFieldsAsFilters,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: { selector: '#cm-garage', class: 'cm_garage_container' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { selector: '#cm-mobile-garage', class: 'cm_mobile-garage_container' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: { selector: '#cm-single-garage', class: 'cm-single-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'MobileSingleVehicleGarage',
      type: 'SingleVehicleGarage',
      location: { selector: '#cm-mobile-single-garage', class: 'cm-mobile-single-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VerifyFitmentStub',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment-stub',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-list',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: !!window.Convermax.config?.categoriesIsMultiLevel,
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'VehicleLandingPageCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-page',
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: !!window.Convermax.config?.categoriesIsMultiLevel,
      visibleIf: () => isVehicleLanding && vehicleLandingPageView === 'categories',
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: window.Convermax.config?.relatedItemsCount || 5,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      initCollapsed: window.Convermax.config?.facetDialogInitCollapsed ?? true,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root__dialog div.price'),
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};

function handleHoverOnItem() {
  const hoverEnter = (e) => {
    const { currentTarget } = e;
    const baseHeight = currentTarget.getBoundingClientRect().height;
    const openHeight = window.innerWidth > 1225 ? baseHeight + 40 : baseHeight + 80; // for flex-wrap correction
    currentTarget.style.setProperty('--base-height', `${baseHeight}px`);
    currentTarget.style.setProperty('--open-height', `${openHeight}px`);
    currentTarget.setAttribute('data-open', true);
    currentTarget.firstChild.setAttribute('data-animation', 'closed=>open');
    setTimeout(() => {
      currentTarget.firstChild.removeAttribute('data-animation');
      currentTarget.firstChild.setAttribute('data-animation-state', 'open');
    }, 200);
  };
  const hoverExit = (e) => {
    const { currentTarget } = e;
    currentTarget.removeAttribute('data-open');
    currentTarget.firstChild.setAttribute('data-animation', 'open=>closed');
    setTimeout(() => {
      currentTarget.firstChild.removeAttribute('data-animation');
      currentTarget.firstChild.setAttribute('data-animation-state', 'closed');
      currentTarget.style.removeProperty('--base-height');
    }, 200);
  };

  const products = document.querySelectorAll('[data-product-item]');
  products.forEach((node) => $(node).mouseenter(hoverEnter));
  products.forEach((node) => $(node).mouseleave(hoverExit));
}

globalThis.Convermax.quickView = (item) => (e) => {
  e.preventDefault();

  const { body } = document;
  const modalContainer = document.querySelector('[data-modal-container]');
  const modalContent = document.querySelector('[data-modal-content]');
  const modalClose = document.querySelector('[data-modal-close]');

  const openModal = () => {
    document.documentElement.classList.add('scroll-locked');
    body.classList.add('modal-loaded', 'modal-visible');
    modalContainer.classList.add('modal--quickshop-full', 'allow-scroll-while-locked');

    fetch(`/products/${item.handle}?view=quickshop`)
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        const doc = new DOMParser().parseFromString(data, 'text/html');
        let productNode = doc.querySelector('.product--outer');

        if (productNode) {
          const buttonMinis = productNode.querySelector('[data-quantity-minus]');
          const quantityInput = productNode.querySelector('[data-quantity-input]');

          const quantityInputHandler = (e) => {
            const { value } = e.target;

            if (value !== '' && (!Number.isInteger(+value) || +value < 2)) {
              e.target.value = 1;
              buttonMinis.classList.add('quantity-selector__button--minus');
            } else {
              buttonMinis.classList.remove('quantity-selector__button--minus');
            }
          };

          quantityInput.addEventListener('input', quantityInputHandler);

          const quantityButtonHandler = (e) => {
            let newValue;

            if (e.target.tagName.toLowerCase() !== 'button') {
              newValue = e.target.closest('[data-quantity-minus]') ? -1 : 1;
            } else {
              newValue = e.target.dataset.quantityMinus === '' ? -1 : 1;
            }

            quantityInput.value = +quantityInput.value + newValue;

            if (+quantityInput.value === 1) {
              buttonMinis.classList.add('quantity-selector__button--minus');
            } else {
              buttonMinis.classList.remove('quantity-selector__button--minus');
            }
          };

          const quantityButtons = productNode.querySelectorAll('.quantity-selector__wrapper button');
          quantityButtons?.forEach((btn) => {
            btn.addEventListener('click', quantityButtonHandler);
          });

          doc.querySelector('.product-gallery--image-background [data-rimg-canvas]')?.remove();
          doc
            .querySelector('.product-gallery--image-background img:not(:first-child)')
            ?.removeAttribute('srcset');
          doc
            .querySelector('.product-gallery--image-background img:not(:first-child)')
            ?.removeAttribute('data-rimg');
        } else {
          productNode = document.createElement('div');
          productNode.innerHTML = 'Something went wrong. Product data not received';
        }

        modalContent.appendChild(productNode);
      });
  };

  const closeModal = () => {
    document.documentElement.classList.remove('scroll-locked');
    body.classList.remove('modal-loaded', 'modal-visible');
    modalContainer.classList.remove('modal--quickshop-full', 'allow-scroll-while-locked');
    modalContent.innerHTML = '';
  };

  modalClose.addEventListener('click', closeModal);
  openModal();
};
