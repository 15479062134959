//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-888:_2056,_5864,_3260,_1424,_2388,_8460,_8536,_1048;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-888')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-888', "_2056,_5864,_3260,_1424,_2388,_8460,_8536,_1048");
        }
      }catch (ex) {
        console.error(ex);
      }