
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'aria-hidden': 'true',
                'focusable': 'false',
                'role': 'presentation',
                'xmlns': 'http://www.w3.org/2000/svg',
                'fill': 'none',
                'viewBox': '0 0 23 24'
            }, _createElement('path', {
                'd': 'M21 21L15.5 15.5',
                'stroke': 'currentColor',
                'strokeWidth': '2',
                'strokeLinecap': 'round'
            }), _createElement('circle', {
                'cx': '10',
                'cy': '9',
                'r': '8',
                'stroke': 'currentColor',
                'strokeWidth': '2'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]